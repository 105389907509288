import IngredientsMethod from "@components/recipes/ingredientsMethod";
import RecipeHero from "@components/recipes/recipeHero";
import { Query, SanityRecipeConnection } from "@graphql-types";
import CategoryPreview from "@shared/categoryPreview";
import Layout from "@shared/layout";
import NewsletterCTA from "@shared/newsletterBlock";
import RecipePreview from "@shared/recipePreview";
import SEO from "@shared/seo";
import { Container, H3 } from "@util/standard";
import { graphql, PageProps } from "gatsby";
import * as React from "react";
import { BreadcrumbPageContext } from "@util/types";
import styled from "styled-components";
import { TABLET_BREAKPOINT } from "@util/constants";
interface QueryData extends Query {
  allPreviewRecipe: SanityRecipeConnection;
}
interface Props extends PageProps {
  data: QueryData;
  pageContext: BreadcrumbPageContext;
}

const RecipeContainer = styled(Container)`
  flex-direction: row;
  overflow: auto;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    > div {
      margin-right: 10px;
      margin-top: 0;
    }
  }
`;

const RecipeInner = (props: Props) => {
  const { data, pageContext } = props;
  const { allPreviewRecipe } = data;
  const recipe = data.allSanityRecipe.nodes[0];

  const defaultMetaTitle = "Recipes | " + recipe.info?.title;
  const defaultMetaDescription = recipe.info?.recipeDescription;
  const defaultMetaImage = recipe.info?.featuredMedia?.image?.asset?.url;

  const shuffled = allPreviewRecipe.nodes.sort(() => 0.5 - Math.random());

  return (
    <>
      <SEO
        seoData={recipe.meta}
        overwriteTitle={
          recipe.meta?.pageTitle ? recipe.meta?.pageTitle : defaultMetaTitle
        }
        overwriteDescription={
          recipe.meta?.pageDescription
            ? recipe.meta?.pageDescription
            : defaultMetaDescription
        }
        overwriteImage={
          recipe.meta?.ogImage?.asset?.url
            ? recipe.meta?.ogImage?.asset?.url
            : defaultMetaImage!
        }
        slug={`recipe/${recipe.info?.slug?.current}`}
      />
      <Layout>
        <Container display="flex" flexDirection="column">
          <RecipeHero recipe={recipe} pageContext={pageContext} />
          <IngredientsMethod recipe={recipe} />
          {pageContext.iban == "nz" && (
            <CategoryPreview
              hideArrows
              products={recipe.related?.relatedProductsNZ}
              isRecipe={true}
            />
          )}
          {/* <NewsletterCTA /> */}

          {recipe.related?.relatedRecipes &&
          recipe.related?.relatedRecipes.length > 0 ? (
            <Container
              display="flex"
              flexDirection="column"
              margin="120px 0 100px 150px"
              tabletMargin="30px 0px 30px 32px"
            >
              <H3 margin="0 .5em 1em 0">Related Recipes</H3>
              <RecipeContainer>
                {recipe.related?.relatedRecipes.map((recipe, i) => {
                  if (!recipe) return;
                  return <RecipePreview key={i} recipe={recipe} />;
                })}
              </RecipeContainer>
            </Container>
          ) : (
            <Container
              display="flex"
              flexDirection="column"
              margin="120px 0 100px 150px"
              tabletMargin="50px 0px 30px 32px"
            >
              <H3 margin="0 .5em 1em 0">More Recipes</H3>
              <RecipeContainer>
                {shuffled.slice(0, 10).map((recipe, i) => {
                  return <RecipePreview key={i} recipe={recipe} />;
                })}
              </RecipeContainer>
            </Container>
          )}
        </Container>
      </Layout>
    </>
  );
};

export default RecipeInner;

export const query = graphql`
  query RecipeInnerQuery($slug: String, $iban: String) {
    allSanityRecipe(filter: { info: { slug: { current: { eq: $slug } } } }) {
      nodes {
        _id
        meta {
          ...sanitySeo
        }
        regions {
          ...sanityRegion
        }
        info {
          ...sanityRecipeInfoFull
        }
        ingredientsMethod {
          ...sanityIngredientMethodInfo
        }
        related {
          relatedBlogs {
            _id
          }
          relatedProductsNZ {
            ...sanityProductPreview
          }
          relatedRecipes {
            _id
            id
            info {
              ...sanityRecipeInfo
            }
          }
          relatedProductsAU {
            ...sanityProductPreviewAu
          }
        }
      }
    }
    allPreviewRecipe: allSanityRecipe(
      limit: 30
      filter: { regions: { elemMatch: { iban: { eq: $iban } } } }
    ) {
      nodes {
        _id
        id
        info {
          ...sanityRecipeInfo
        }
      }
    }
  }
`;
