import {
  Maybe,
  SanityIngredientGroup,
  SanityRecipe,
  SanityMethodGroup,
} from "@graphql-types";
import { A, Container, H3, H4, VerticalSeparator } from "@util/standard";
import * as React from "react";
import styled from "styled-components";
import Fraction from "fraction.js";

interface Props {
  recipe?: SanityRecipe;
}

const StyledMethodStep = styled.ol`
  padding-left: 20px;
  li {
    margin-bottom: 24px;
  }
`;

const StyledIngredientList = styled.ul`
  padding-left: 20px;
  width: 80%;
`;

const IngredientsMethod = (props: Props) => {
  const { recipe } = props;

  const ingredientGroups: Maybe<Maybe<SanityIngredientGroup>[]> | undefined =
    recipe?.ingredientsMethod?.ingredientsGroup;
  const methodGroups: Maybe<Maybe<SanityMethodGroup>[]> | undefined =
    recipe?.ingredientsMethod?.methods;

  return (
    <Container backgroundColor="mauve">
      <Container
        width="70%"
        margin="90px auto"
        display="flex"
        mobileWidth="100%"
        isMobileColumn
      >
        <Container
          width="30%"
          display="flex"
          flexDirection="column"
          mobileWidth="80%"
        >
          <H3 fontSize={23} fontFamily="Poppins">
            Ingredients
          </H3>
          {ingredientGroups &&
            ingredientGroups.length > 0 &&
            ingredientGroups.map((ingredientGroup, i) => {
              if (ingredientGroup == null) return null;

              return (
                <Container key={i} display="flex" flexDirection="column">
                  {ingredientGroup?.group && (
                    <H4 fontWeight="normal" fontFamily="Poppins">
                      {ingredientGroup.group}
                    </H4>
                  )}
                  <StyledIngredientList>
                    {ingredientGroup?.ingredients &&
                      ingredientGroup?.ingredients.length > 0 &&
                      ingredientGroup?.ingredients?.map((ingredient, i) => {
                        if (ingredient == null) return null;

                        const measureFraction =
                          ingredient.measure === 0.333
                            ? "1/3"
                            : ingredient.measure === 0.666
                            ? "2/3"
                            : new Fraction(ingredient.measure ?? 0).toFraction(
                                true
                              );
                        return (
                          <React.Fragment key={i}>
                            {ingredient?.ingredient?.clickableLink?.url &&
                            ingredient?.ingredient?.clickableLink?.linktext ? (
                              <li>
                                {ingredient?.displayValue ? (
                                  <>{ingredient?.displayValue} </>
                                ) : (
                                  <>
                                    {ingredient.measure && measureFraction}{" "}
                                    {/* {ingredient?.measureType} */}
                                    {ingredient?.measureReference?.title}{" "}
                                  </>
                                )}
                                <A
                                  href={
                                    ingredient?.ingredient?.clickableLink.url
                                  }
                                  bold
                                  color="merlin"
                                  target="blank"
                                >
                                  {ingredient?.ingredient?.displayName}
                                </A>
                              </li>
                            ) : (
                              <li key={ingredient._key}>
                                {ingredient?.displayValue ? (
                                  <>{ingredient?.displayValue} </>
                                ) : (
                                  <>
                                    {ingredient.measure && measureFraction}{" "}
                                    {/* {ingredient?.measureType} */}
                                  </>
                                )}
                                {ingredient?.measureReference?.title}{" "}
                                {ingredient?.displayTitle || ingredient?.ingredient?.displayName}
                              </li>
                            )}
                          </React.Fragment>
                        );
                      })}
                  </StyledIngredientList>
                </Container>
              );
            })}
        </Container>
        <VerticalSeparator color="barkersDarkGray" />
        <Container
          width="70%"
          display="flex"
          flexDirection="column"
          padding="0px 0px 0px 60px"
          mobileWidth="80%"
          mobilePadding="0px"
        >
          <H3 fontSize={23} fontFamily="Poppins">
            Method
          </H3>
          {methodGroups &&
            methodGroups.length > 0 &&
            methodGroups.map((methodGroup, i) => {
              return (
                <Container display="flex" flexDirection="column" key={i}>
                  {methodGroup?.methodGroup && (
                    <H4 fontFamily="Poppins">{methodGroup.methodGroup}</H4>
                  )}
                  <StyledMethodStep>
                    {methodGroup?.steps &&
                      methodGroup.steps.length > 0 &&
                      methodGroup.steps.map((step, i) => (
                        <li key={i}>{step}</li>
                      ))}
                  </StyledMethodStep>
                </Container>
              );
            })}
        </Container>
      </Container>
    </Container>
  );
};

export default IngredientsMethod;
